import React from "react";

const InsuranceInfo = () => {
  return (
    <div className="w-full px-8 flex flex-col py-16 justify-center items-center">
      <div className="w-full lg:w-3/5 flex flex-col justify-center items-start">
        <p className="text-lg text-gray-600 mb-4 font-bold">
          We accept these insurance groups:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-4">
          <li>Aetna</li>
          <li>Blue Cross Blue Shield</li>
          <li>Cigna</li>
          <li>United Health Care</li>
          <li>Avmed</li>
          <li>Beech Street</li>
          <li>Florida Hospital Healthcare System</li>
          <li>GHI</li>
          <li>Greatwest</li>
          <li>Golden Rule</li>
          <li>Humana</li>
          <li>Medicare</li>
          <li>Medicare Rail Road</li>
          <li>Multiplan</li>
          <li>Tricare</li>
          <li>Physician United Plan</li>
        </ul>
        <p className="text-lg text-gray-600 font-bold">
          If you do not see your insurance plan, please call and we will verify
          for you.
        </p>
      </div>
    </div>
  );
};

export default InsuranceInfo;
